import React from 'react';
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <SectionWrapper className={styles.container}>
    <Typography variant='h2'
      weight='bold'
      className={styles.titleContainer}>
      {i18n('Leverage links to data sources')}
    </Typography>
  </SectionWrapper>
);