import React from 'react';
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <SectionWrapper className={styles.container}>
    <Typography variant='h2'
      weight='bold'
      className={styles.titleContainer}>
      {i18n('OBI+ Platform overview')}
    </Typography>
    <Typography variant='subtitle2'
      weight='light'
      className={styles.subtitleContainer}>
      {i18n('It is a powerful platform designed to enable connected car services at scale. All a business needs to expand its portfolio quickly and cost-effectively.')}
    </Typography>
  </SectionWrapper>
);