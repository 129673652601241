import React from "react";
import Content from '../views/Platform';

export default ({location}) => <Content location={location} seo={{
  title: "En platform til at implementere connected car tjenester",
  lang: 'da-DK',
  description: "OBI+ er en connected car platform, der tilbyder en række løsninger til køretøjer til virksomheder, værksteder, bilister, bilforhandlere og leasingselskaber.",
  meta: [{
    name: 'keywords',
    content: 'Connected Car platform',
  }]
}}/>