import React from 'react';
import Img from "gatsby-image/withIEPolyfill";
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default ({title, subtitle, image}) => (
    <Grid item container xs={12} sm={6} md={4} lg={3} xl={3}
        alignContent='center'
        alignItems='center'
        justify='flex-start'
        className={styles.toolWrap}>
        <div className={styles.toolImageContainer}>
            <Img imgStyle={{ objectFit: 'none' }} 
                objectPosition='top center'     
                objectFit='none'
                className={styles.toolImage}
                fluid={image.fluid}
                alt={i18n(title)}/>
        </div>
        <Typography variant='h3'
            weight='bold'
            align='left'
            className={styles.toolTitle}>
            {title}
        </Typography>
        <Typography variant='subtitle2'
            weight='light'
            align='left'
            className={styles.toolSubtitle}>
            {subtitle}
        </Typography>
    </Grid>
);